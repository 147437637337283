<template>
  <v-card :loading="loading" max-width="416" max-height="524" width="416" min-height="524" elevation="1">
    <v-row class="cardContent" style="cursor: pointer" @click.prevent="openRealEstate()">
      <v-col class="pa-0">
        <v-row>
          <v-col class="pt-7 pb-0">
            <v-img max-height="203" max-width="379" :src="tempImage" :lazy-src="tempImage" @error="imageError">
              <v-row class="h50">
                <v-col>
                  <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <v-btn v-if="mobileBreakpoint != true" color="white" class="text-h6 white--text pl-0 imgBtn" icon>
                      <v-icon>mdi-checkbox-blank-circle-outline</v-icon>
                    </v-btn>
                    <span class="timeAgoSpan" v-else>
                      {{ timeAgo(RegisterationDay) }}
                    </span>
                    <div></div>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                </v-col>
              </v-row>
              <v-row class="h50">
                <v-col class="d-flex align-end">
                  <v-row class="pl-6 d-flex align-center">
                    <span v-if="booleanVip" class="textDecorationBold vipClass mr-2">
                      VIP
                    </span>
                    <span v-if="showDifPrice" class="textDecorationBold discountClass pl-2 pr-2">
                      {{ getPrice.type + " " + getPrice.percentage + "%" }}
                    </span>
                  </v-row>
                </v-col>
              </v-row>
            </v-img>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col class="pa-0" cols="12" xs="12" sm="7" md="7" lg="7" xl="7">
            <v-card-title class="textDecorationBold pb-2" style="color: #263573">
              <v-tooltip bottom :disabled="
                txtLength(
                  (title != undefined ? title : '') +
                  ' ' +
                  (unit != undefined ? '|' + unit + ' τ.μ.' : '')
                ) < (mobileBreakpoint == true ? 60 : 27)
              ">
                <template v-slot:activator="{ on, attrs }">
                  <div class="font16 pb-0 text-truncate" align="left" v-bind="attrs" v-on="on">
                    {{
                    textOverFlow(
                    (![undefined, "", null].includes(title)
                    ? title
                    : "--") +
                    " " +
                    (![undefined, "", null].includes(unit)
                    ? "|" + unit + " τ.μ."
                    : "--"),
                    mobileBreakpoint == true ? 60 : 27
                    )
                    }}
                  </div>
                </template>
                <span>{{ ![undefined, "", null].includes(title) ? title : "--" }}
                  {{
                  ![undefined, "", null].includes(unit)
                  ? "|" + unit + " τ.μ."
                  : "--"
                  }}</span>
              </v-tooltip>
            </v-card-title>
          </v-col>
          <v-col class="pt-6" cols="12" xs="12" sm="5" md="5" lg="5" xl="5">
            <v-row>
              <v-col cols="" v-for="item in propertiesArray" :key="item.id">
                <v-row class="pr-3 flex-grow-0">
                  <v-col class="pr-0 flex-grow-0">
                    <v-img :src="require('@/assets/' + item.image)" :min-height="18" :min-width="22" :max-height="18"
                      :max-width="22"> </v-img>
                  </v-col>
                  <v-col class="pr-0 flex-grow-0">
                    <span> {{ item.value }} </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>



        </v-row>
        <v-row class="mt-0">
          <v-col class="pa-0">
            <v-card-text class="textFontDecoration">
              <v-row>
                <v-col class="pt-0 pb-0">
                  <h3 class="font14 textContent textDecorationBold" align="left">
                    {{ ![undefined, "", null].includes(price) ? price : "--" }}
                  </h3>
                </v-col>
              </v-row>
              <v-row class="mt-2">
                <v-col class="pt-0 pb-0">
                  <v-tooltip bottom :disabled="txtLength(location) < 33">
                    <template v-slot:activator="{ on, attrs }">
                      <div class="textContent font12 pb-0 text-truncate" align="left" v-bind="attrs" v-on="on">
                        {{
                        ![undefined, "", null].includes(location)
                        ? textOverFlow(
                        location,
                        mobileBreakpoint == true ? 60 : 32
                        )
                        : "--"
                        }}
                      </div>
                    </template>
                    <span>{{
                    ![undefined, "", null].includes(location)
                    ? location
                    : "--"
                    }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
              <v-row class="mt-0 mb-0">
                <v-col class="pt-0 pb-0">
                  <div class="textContent textDecorationBold font14 veryDarkGrayColor" align="right">
                    {{ code }}
                  </div>
                </v-col>
              </v-row>
              <v-row align="center" class="mt-0">
                <v-col class="
                    text-no-wrap
                    textContent
                    textDecorationBold
                    ml-3
                    flex-grow-0
                  ">
                  <span>
                    {{
                    ![undefined, "", null].includes(typeName)
                    ? typeName
                    : "--"
                    }}
                  </span>
                </v-col>
                <v-col class="mb-2 flex-grow-0 ml-8 pl-7" v-if="mobileBreakpoint != true">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div class="font16 pb-0" align="left" v-bind="attrs" v-on="on">
                        <v-img height="23px" width="23px" :src="require('@/assets/realEstateCard/global.svg')">
                        </v-img>
                      </div>
                    </template>
                    <span> {{ timeAgo(RegisterationDay) }} </span>
                  </v-tooltip>
                </v-col>
                <v-col class="pl-0">
                  <div class="text-no-wrap textContent textFontDecoration font14" style="color: #263573" align="right">
                    {{ typeExtraContent }}
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-divider></v-divider>
              </v-row>
              <v-row class="botRow mt-3 pb-1">
                <v-col cols="5" xs="5" sm="5" md="5" lg="5" xl="5" class="align-self-center  pr-0">
                  <v-progress-linear :height="5" :value="similarity" color="#77BD86" />
                </v-col>
                <v-col cols="7" xs="7" sm="7" md="7" lg="7" xl="7" align="right" class="font14 cusTab flex-grow-0">
                  <span> {{ getSimilarity }} </span>
                </v-col>
              </v-row>

              <v-row>
                <v-divider></v-divider>
              </v-row>
              <v-row class="avatarClass mx-n6 align-center" style="height: 93px !important">
                <v-col class="">
                  <AvatarField :data="getPhoto(fromDetails)" :isImg="fromDetails.img ? true : false" :showName="true"
                    :nameContent="(fromDetails.name ? fromDetails.name + ' ' : '') + (fromDetails.surname ? fromDetails.surname : '')"
                    fontSizeName="16px" fontSizeSubContent="16px" :showSubContent="true" nameContentClass="textDecorationBold primaryColor"
                    subContentClass="primaryColor  textFontDecoration" :subContent="fromDetails.company ? fromDetails.company : ''"
                    backgroundColor="#77BD86" :size="31" fontSizeImg="14px" rowClass="" />

                </v-col>

              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-card>
</template>

<script>
/* 
  Name:PropertyCard
  Description: Card to show real estate with basic info
  API Property   
    id: String, Number for the id of card
    cardClass:  String for the card class
    image: String for the image of card
    title: String for the tile of card
    price: String, Number for the price of object
    location: String for location of object
    code: String for unique code of card/object
    typeName: String for the title of type
    typeExtraContent: String for the sub title of type
    status: String for status of object
    openRealEstateSliderHandler: Function on open card
    onDelete: Function on delete
    unit: String, Number for unit price pre square feet
    prices: Array for hint of price
    booleanVip: Boolean if vip can be true or false
    RegisterationDay: String, Number for date registered
    propertiesArray: Array for propetries
    similarity: Number for similarity per cent
    fromDetails: Object for the property avatar

  Components Used:
    AvatarField
*/
import CoreHelper from "@/helpers/Core/core.js";
import { mapState } from "vuex";
import AvatarField from "@/components/Fields/AvatarField/AvatarField.vue";

export default {
  name: "PropertyCard",
  props: {
    id: [String, Number], // Id of card
    cardClass: { type: String, default: "defaultEstateCard" },
    image: String, // Image of card
    title: String, // Tile of card
    price: [String, Number], // price of object
    location: String, // location of object
    code: String, // unique code of card/object
    typeName: String, // Title of type
    typeExtraContent: String, //Sub title of type
    status: String, // status of object
    openRealEstateSliderHandler: Function, // Handler on open card
    onDelete: Function,
    unit: [String, Number], //τιμη τμ
    prices: Array, // hint of price
    booleanVip: Boolean, //vip can be true or false
    RegisterationDay: [String, Number], //Date registered
    propertiesArray: Array,
    similarity: Number,
    fromDetails: Object,
  },
  components: {
    AvatarField,
  },
  created() {
    this.tempImage = this.getImage(this.image);
  },
  data() {
    return {
      loading: false,
      loader: false,
      loader2: false,
    };
  },
  computed: {
    ...mapState({
      userDetails: (state) => state.auth.userDetails,
    }),
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    showDifPrice() {
      if (this.prices) return this.prices.length > 1;

      return false;
    },
    getSimilarity() {
      return isNaN(this.similarity) == true ? this.similarity : this.similarity + '%'
    },
    getStatusClass() {
      if (
        ["Υπο διαπραγμάτευση", "Μη ενεργό", "Ενεργό", "Προκαταβολή"].includes(
          this.status
        )
      ) {
        return "whiteStatusBox";
      } else if (["Πουλήθηκε", "Ενοικιάστηκε"].includes(this.status)) {
        return "redStatusBox textDecorationBold";
      } else if (this.status == "Πρόχειρο") {
        return "grayStatusBox";
      } else if (this.status == "Μη δημοσιευμένη") {
        return "darkGrayStatusBox";
      } else {
        return "";
      }
    },
    getPrice() {
      let max = this.prices.length;
      if (max > 1) {
        let type =
          this.prices[max - 1].change_literal == "initial_price"
            ? "Αρχική τιμή"
            : this.prices[max - 1].change_literal == "price_increase"
              ? "ΑΥΞΗΣΗ ΤΙΜΗΣ"
              : "ΜΕΙΩΣΗ ΤΙΜΗΣ";
        return {
          type: type,
          percentage: this.prices[max - 1].change_percentage,
        };
      }
      return "";
    },
  },
  methods: {
    txtLength(txt) {
      try {
        return txt.length;
      } catch {
        return 0;
      }
    },
    textOverFlow(text, length) {
      try {
        if (text.length <= length) return text;
        else return text.substr(0, length) + " ..";
      } catch {
        return text;
      }
    },
    getPhoto(obj) {
      return obj.img ? obj.img
        : obj.name && obj.surname ? obj.name.charAt(0) + obj.surname.charAt(0)
          : obj.name ? obj.name.charAt(1)
            : obj.surname ? obj.surname.charAt(1)
              : ""
    },
    openRealEstate() {
      this.openRealEstateSliderHandler();
    },
    imageError() {
      this.tempImage = require("@/assets/realEstateCard/no-image.png");
    },
    getImage() {
      return CoreHelper.getImagePath({ path: this.image });
    },
    timeAgo(date) {
      try {
        return CoreHelper.time_ago_card(date);
      } catch {
        return "1";
      }
    },

  },
};
</script>

<style scoped>
.vipClass {
  background: rgba(232, 144, 8, 0.75);
  color: #ffffff;
  width: 40px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeAgoSpan {
  font-family: "InterMedium";
  font-size: 14px;
  background-color: #263573b5;
  border-radius: 5px;
  padding: 2px 8px 3px 8px;
  color: white;
}

.discountClass {
  background: #e52628;
  color: #ffffff;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.whiteStatusBox {
  font-size: 13px !important;
  color: #263573 !important;
  border: solid 2px rgba(112, 112, 112, 0.39) !important;
}

.redStatusBox {
  font-size: 13px !important;
  color: #ffffff !important;
  background-color: #e52628 !important;
}

.grayStatusBox {
  font-size: 13px !important;
  color: #ffffff !important;
  background-color: #dadada !important;
}

.darkGrayStatusBox {
  font-size: 13px !important;
  color: #263573 !important;
  background-color: #43425d !important;
}

.cardContent {
  margin: 5% 5% 0% 5%;
}

.textContent {
  padding: 0px 0px 9px 0px !important;
}

.imgContent {
  margin: 0px !important;
  border-radius: 5px;
}

.avatarClass {
  background-color: #b1b1b1 !important;
}

.textTranformUnset {
  text-transform: unset !important;
}

.imgBtn {
  height: 35px !important;
  width: 35px !important;
}

.font16 {
  font-size: 16px;
}

.font14 {
  font-size: 14px;
}

.font12 {
  font-size: 12px;
}

.btn {
  text-transform: unset !important;
  color: #263573;
}

.approveClass {
  font-size: 13px;
  background-color: #77bd85 !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
}

.rejectClass {
  font-size: 13px;
  background-color: #e52628 !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
}

.opacity {
  filter: brightness(0.5);
}

.forApproveClass {
  font-size: 16px !important;
  color: #ffffff !important;
  position: absolute;
  width: 100%;
  top: 25%;
  cursor: pointer;
}

.h25 {
  height: 25%;
}

.h50 {
  height: 50%;
}
</style>
