var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"loading":_vm.loading,"max-width":"416","max-height":"524","width":"416","min-height":"524","elevation":"1"}},[_c('v-row',{staticClass:"cardContent",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.preventDefault();return _vm.openRealEstate()}}},[_c('v-col',{staticClass:"pa-0"},[_c('v-row',[_c('v-col',{staticClass:"pt-7 pb-0"},[_c('v-img',{attrs:{"max-height":"203","max-width":"379","src":_vm.tempImage,"lazy-src":_vm.tempImage},on:{"error":_vm.imageError}},[_c('v-row',{staticClass:"h50"},[_c('v-col',[_c('v-app-bar',{attrs:{"flat":"","color":"rgba(0, 0, 0, 0)"}},[(_vm.mobileBreakpoint != true)?_c('v-btn',{staticClass:"text-h6 white--text pl-0 imgBtn",attrs:{"color":"white","icon":""}},[_c('v-icon',[_vm._v("mdi-checkbox-blank-circle-outline")])],1):_c('span',{staticClass:"timeAgoSpan"},[_vm._v(" "+_vm._s(_vm.timeAgo(_vm.RegisterationDay))+" ")]),_c('div'),_c('v-spacer')],1)],1)],1),_c('v-row',{staticClass:"h50"},[_c('v-col',{staticClass:"d-flex align-end"},[_c('v-row',{staticClass:"pl-6 d-flex align-center"},[(_vm.booleanVip)?_c('span',{staticClass:"textDecorationBold vipClass mr-2"},[_vm._v(" VIP ")]):_vm._e(),(_vm.showDifPrice)?_c('span',{staticClass:"textDecorationBold discountClass pl-2 pr-2"},[_vm._v(" "+_vm._s(_vm.getPrice.type + " " + _vm.getPrice.percentage + "%")+" ")]):_vm._e()])],1)],1)],1)],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12","xs":"12","sm":"7","md":"7","lg":"7","xl":"7"}},[_c('v-card-title',{staticClass:"textDecorationBold pb-2",staticStyle:{"color":"#263573"}},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.txtLength(
                (_vm.title != undefined ? _vm.title : '') +
                ' ' +
                (_vm.unit != undefined ? '|' + _vm.unit + ' τ.μ.' : '')
              ) < (_vm.mobileBreakpoint == true ? 60 : 27)},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"font16 pb-0 text-truncate",attrs:{"align":"left"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(_vm.textOverFlow( (![undefined, "", null].includes(_vm.title) ? _vm.title : "--") + " " + (![undefined, "", null].includes(_vm.unit) ? "|" + _vm.unit + " τ.μ." : "--"), _vm.mobileBreakpoint == true ? 60 : 27 ))+" ")])]}}])},[_c('span',[_vm._v(_vm._s(![undefined, "", null].includes(_vm.title) ? _vm.title : "--")+" "+_vm._s(![undefined, "", null].includes(_vm.unit) ? "|" + _vm.unit + " τ.μ." : "--"))])])],1)],1),_c('v-col',{staticClass:"pt-6",attrs:{"cols":"12","xs":"12","sm":"5","md":"5","lg":"5","xl":"5"}},[_c('v-row',_vm._l((_vm.propertiesArray),function(item){return _c('v-col',{key:item.id,attrs:{"cols":""}},[_c('v-row',{staticClass:"pr-3 flex-grow-0"},[_c('v-col',{staticClass:"pr-0 flex-grow-0"},[_c('v-img',{attrs:{"src":require('@/assets/' + item.image),"min-height":18,"min-width":22,"max-height":18,"max-width":22}})],1),_c('v-col',{staticClass:"pr-0 flex-grow-0"},[_c('span',[_vm._v(" "+_vm._s(item.value)+" ")])])],1)],1)}),1)],1)],1),_c('v-row',{staticClass:"mt-0"},[_c('v-col',{staticClass:"pa-0"},[_c('v-card-text',{staticClass:"textFontDecoration"},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('h3',{staticClass:"font14 textContent textDecorationBold",attrs:{"align":"left"}},[_vm._v(" "+_vm._s(![undefined, "", null].includes(_vm.price) ? _vm.price : "--")+" ")])])],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('v-tooltip',{attrs:{"bottom":"","disabled":_vm.txtLength(_vm.location) < 33},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"textContent font12 pb-0 text-truncate",attrs:{"align":"left"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(![undefined, "", null].includes(_vm.location) ? _vm.textOverFlow( _vm.location, _vm.mobileBreakpoint == true ? 60 : 32 ) : "--")+" ")])]}}])},[_c('span',[_vm._v(_vm._s(![undefined, "", null].includes(_vm.location) ? _vm.location : "--"))])])],1)],1),_c('v-row',{staticClass:"mt-0 mb-0"},[_c('v-col',{staticClass:"pt-0 pb-0"},[_c('div',{staticClass:"textContent textDecorationBold font14 veryDarkGrayColor",attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.code)+" ")])])],1),_c('v-row',{staticClass:"mt-0",attrs:{"align":"center"}},[_c('v-col',{staticClass:"\n                  text-no-wrap\n                  textContent\n                  textDecorationBold\n                  ml-3\n                  flex-grow-0\n                "},[_c('span',[_vm._v(" "+_vm._s(![undefined, "", null].includes(_vm.typeName) ? _vm.typeName : "--")+" ")])]),(_vm.mobileBreakpoint != true)?_c('v-col',{staticClass:"mb-2 flex-grow-0 ml-8 pl-7"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"font16 pb-0",attrs:{"align":"left"}},'div',attrs,false),on),[_c('v-img',{attrs:{"height":"23px","width":"23px","src":require('@/assets/realEstateCard/global.svg')}})],1)]}}],null,false,2885221128)},[_c('span',[_vm._v(" "+_vm._s(_vm.timeAgo(_vm.RegisterationDay))+" ")])])],1):_vm._e(),_c('v-col',{staticClass:"pl-0"},[_c('div',{staticClass:"text-no-wrap textContent textFontDecoration font14",staticStyle:{"color":"#263573"},attrs:{"align":"right"}},[_vm._v(" "+_vm._s(_vm.typeExtraContent)+" ")])])],1),_c('v-row',[_c('v-divider')],1),_c('v-row',{staticClass:"botRow mt-3 pb-1"},[_c('v-col',{staticClass:"align-self-center  pr-0",attrs:{"cols":"5","xs":"5","sm":"5","md":"5","lg":"5","xl":"5"}},[_c('v-progress-linear',{attrs:{"height":5,"value":_vm.similarity,"color":"#77BD86"}})],1),_c('v-col',{staticClass:"font14 cusTab flex-grow-0",attrs:{"cols":"7","xs":"7","sm":"7","md":"7","lg":"7","xl":"7","align":"right"}},[_c('span',[_vm._v(" "+_vm._s(_vm.getSimilarity)+" ")])])],1),_c('v-row',[_c('v-divider')],1),_c('v-row',{staticClass:"avatarClass mx-n6 align-center",staticStyle:{"height":"93px !important"}},[_c('v-col',{},[_c('AvatarField',{attrs:{"data":_vm.getPhoto(_vm.fromDetails),"isImg":_vm.fromDetails.img ? true : false,"showName":true,"nameContent":(_vm.fromDetails.name ? _vm.fromDetails.name + ' ' : '') + (_vm.fromDetails.surname ? _vm.fromDetails.surname : ''),"fontSizeName":"16px","fontSizeSubContent":"16px","showSubContent":true,"nameContentClass":"textDecorationBold primaryColor","subContentClass":"primaryColor  textFontDecoration","subContent":_vm.fromDetails.company ? _vm.fromDetails.company : '',"backgroundColor":"#77BD86","size":31,"fontSizeImg":"14px","rowClass":""}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }