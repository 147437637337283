<template>
  <v-col>
    <v-row class="">
      <v-col cols="12" xs="12" sm="7" md="7" lg="7" xl="7">
        <v-row class="mx-8">
          <v-col class="px-8">
            <v-row class="my-4">
              <v-col class="flex-grow-0" align="left">
                <v-chip small label color="#707070" text-color="white">
                  {{ callDate }}
                </v-chip>
              </v-col>
              <v-col class="flex-grow-0" align="left">
                <v-chip small label color="#707070" text-color="white">
                  {{ callTime }}
                </v-chip>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="flex-grow-0">
                <Icon icon="carbon:phone-incoming" color="#e89008" :height="31" :width="34" />
              </v-col>
              <v-col class="pl-0">
                <HeaderTitle :title="$t('callFrom')" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
                <v-text-field v-model="quickSearch" :label="$t('quickSearch') + '...'" outlined dense>
                </v-text-field>
              </v-col>
              <v-col class="flex-grow-0">
                <v-btn outlined class="btn-custom" min-height="40" min-width="40">
                  <Icon icon="eva:edit-outline" color="#263573" width="18" height="18" />
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-n6 mb-1">
              <v-col>
                <AvatarField :size="30" :showName="true" fontSizeImg="16px" :data="getPhoto(callerObject)"
                  :isImg="callerObject.img ? true : false"
                  :nameContent="(callerObject.name ? callerObject.name + ' ' : '') + (callerObject.surname ? callerObject.surname : '')"
                  nameContentClass="primaryColor" />
              </v-col>
            </v-row>
            <v-row class="">
              <v-col cols="9">
                <v-row>
                  <v-col :class="mobileBreakpoint == true ? '' : ''" class="py-0">
                    <v-row class="flex-nowrap">
                      <v-col class="flex-grow-0" align="left">
                        <Icon icon="akar-icons:mobile-device" color="#e89008" width="25" height="25" />
                      </v-col>
                      <v-col class="font14 flex-grow-0 pl-0 ">
                        <span>
                          {{callerObject.mobile}}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col :class="mobileBreakpoint == true ? ' ' : ''" class="py-0">
                    <v-row class="flex-nowrap ">
                      <v-col class="flex-grow-0  orangeText " align="left">
                        <Icon icon="carbon:phone" color="#e89008" width="23" height="23" />
                      </v-col>
                      <v-col class="font14 flex-grow-0 pl-0" align="left">
                        <span>
                          {{callerObject.phone}}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col :class="mobileBreakpoint == true ? '' : ''" class="py-0">
                    <v-row class="flex-nowrap">
                      <v-col class="flex-grow-0" align="left">
                        <Icon icon="clarity:email-line" color="#e89008" width="25" height="25" />
                      </v-col>
                      <v-col class="font14 flex-grow-0 pl-0 ">
                        <span>
                          {{callerObject.email}}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col :class="mobileBreakpoint == true ? ' ' : ''" class="py-0">
                    <v-row class="flex-nowrap ">
                      <v-col class="flex-grow-0  orangeText " align="left">
                        <span class="font14">
                          {{ $t("vatNumber") }}
                        </span>
                      </v-col>
                      <v-col class="font14 flex-grow-0 pl-0" align="left">
                        <span>
                          {{callerObject.afm}}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>





                <v-row class="">
                  <v-col class="flex-grow-0 pr-0 py-0" align="left">
                    <Icon icon="ic:baseline-gps-fixed" color="#e89008" width="25" height="25" />
                  </v-col>
                  <v-col class="font14 py-0" align="left">
                    <span>
                      {{callerObject.location}}
                    </span>
                  </v-col>
                </v-row>

                <v-row class="">
                  <v-col class="flex-grow-0 pr-0 py-0" align="left">
                    <Icon icon="akar-icons:plus" color="#e89008" width="25" height="25" />
                  </v-col>
                  <v-col class="font14 py-0" align="left">
                    <span>
                      {{callerObject.from}}
                    </span>
                  </v-col>
                </v-row>

                <v-row class="">
                  <v-col class="flex-grow-0 pr-0 pt-0" align="left">
                    <Icon icon="bi:chat-right" color="#e89008" width="25" height="25" />
                  </v-col>
                  <v-col class="font14 pt-0" align="left">
                    <span>
                      {{callerObject.profileComment}}
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>


          </v-col>
        </v-row>

        <v-row>
          <v-col class="px-0">
            <v-divider />
          </v-col>
        </v-row>


        <v-row class="mx-8">
          <v-col class="px-8">
            <v-row class="mt-3 mb-0">
              <v-col cols="12" xs="12" sm="7" md="6" lg="6" xl="6" :align="mobileBreakpoint == true ? 'left' : ''"
                :class="mobileBreakpoint == true ? 'pl-0' : ''">
                <v-row>
                  <v-col class="flex-grow-0 pr-0" align="left">
                    <Icon icon="ic:outline-real-estate-agent" color="#e89008" :height="31" :width="34" />
                  </v-col>
                  <v-col class="pr-0" align="left">
                    <HeaderTitle :title="$t('demand')" />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" xs="12" sm="7" md="6" lg="6" xl="6" :align="mobileBreakpoint == true ? 'left' : 'right'"
                :class="mobileBreakpoint == true ? 'pl-0' : 'pt-5'">
                <a class="textFontDecoration" :style="'color: #E52628; font-size: 14px;  font-weight: bold; '"
                  @click="onClear">
                  {{ $t("clear") }}
                </a>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col cols="12" align="left" class="primaryColor font14 textDecorationBold">
                <span>
                  {{ $t("assignmentPartner")}}
                </span>
              </v-col>
              <v-col class="pt-0" cols="12" xs="12" sm="8" md="8" lg="8" xl="8">
                <v-text-field v-model="quickPartnerSearch" :label="$t('quickPartnerSearch') + '...'" outlined dense>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="left" class="primaryColor font14 textDecorationBold pb-0">
                <span>
                  {{ $t("basicInfo")}}
                </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" :align="mobileBreakpoint == true ? 'left' : ''"
                :class="mobileBreakpoint == true ? 'pl-0' : ''">
                <v-text-field v-model="propertyCode" :label="$t('propertyCode') + '...'" outlined dense>
                </v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" :align="mobileBreakpoint == true ? 'left' : ''"
                :class="mobileBreakpoint == true ? 'pl-0' : ''">
                <v-menu ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition"
                  offset-y min-width="auto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field dense outlined :label="$t('nextFollowUp') + '...'" v-model="nextFollowUpFormatted"
                      prepend-inner-icon="mdi-calendar" class="" @blur="
                        nextFollowUpStart = parseDate(
                          nextFollowUpFormatted,
                          'nextFollowUpStart'
                        )
                      ">
                      <template v-slot:prepend-inner>
                        <v-icon v-bind="attrs" v-on="on"> mdi-calendar </v-icon>
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker locale="el" v-model="nextFollowUpStart" scrollable @input="menu1 = false" />
                </v-menu>
              </v-col>
            </v-row>
            <v-row class="mt-n3 mb-n5">
              <v-col align="left">
                <span class="textDecorationBold" style="font-size: 14px">
                  {{ $t("availableForTable2") }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-5" align="left">
                <v-btn-toggle v-model="Task" flex>
                  <v-btn v-for="button in availableForList" :key="button.id" :min-width="126" :max-width="126"
                    :min-height="40" :max-height="40" class="btn recBtnWidth"
                    :class="mobileBreakpoint == true ? 'mb-2 mr-2' : 'mr-3'" outlined>
                    {{ $t(button.text) }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row class=" mb-n5">
              <v-col class="textDecorationBold" style="font-size: 14px" align="left">
                <span>
                  {{ $t("propertyCategories") }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pr-0" align="left">
                <v-btn-toggle v-model="Category">
                  <v-btn v-for="button in getCategoryList" :key="button.id" class="mr-3 my-1 btn recBtn" outlined
                    :max-width="126" :min-width="126" :max-height="108" :min-height="108">
                    <v-col>
                      <v-row>
                        <v-col align="center" class="pb-0">
                          <v-img :src="
                            require('@/assets/CreateEditRealEstate/' +
                              button.id +
                              '.svg')
                          " height="48" width="48" />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col>
                          <span> {{ $t(button.text) }} </span>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="mt-n2" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-select v-model="subCategory" :items="getSubCategoryList()" :height="30"
                  :label="$t('selectPropSubcat') + '...'" item-text="text" item-value="val">
                  <template v-slot:selection="{ item }">
                    <v-row class="flex-grow-0">
                      <v-col style="display: flex; align-items: center">
                        {{ $t(item.text) }}
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list>
                      <v-list-item-title>
                        <v-row>
                          <v-col style="display: flex; align-items: center">
                            {{ $t(item.text) }}
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <Location :placeholder="$t('searchLoc') + ' Google...'" :showNewDealList="true" iconColor="primary"
                  :vuexProps="false" />
              </v-col>
            </v-row>

            <v-row class="mt-1 mb-n5">
              <v-col class="" align="left">
                <span class="textDecorationBold" style="font-size: 14px">
                  {{ $t("priceTable") }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field outlined dense v-model="priceFrom" :label="$t('from') + '.'"
                  :class="mobileBreakpoint == true ? 'mb-n5' : ''">
                  <template slot="append">
                    <span class="textDecorationBold primaryColor" style="font-size: 14px; margin-top: 4px">
                      {{ currency }}
                    </span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col class="" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field outlined dense v-model="priceTo" :label="$t('toDt2') + '...'">
                  <template slot="append">
                    <span class="textDecorationBold primaryColor" style="font-size: 14px; margin-top: 4px">
                      {{ currency }}
                    </span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>



            <v-row class="mt-1 mb-n5">
              <v-col class="" align="left">
                <span class="textDecorationBold" style="font-size: 14px">
                  {{ $t("priceSqft") }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field outlined dense v-model="priceSqftFrom" :label="$t('from') + '.'"
                  :class="mobileBreakpoint == true ? 'mb-n5' : ''">
                  <template slot="append">
                    <span class="textDecorationBold primaryColor" style="font-size: 14px; margin-top: 4px">
                      {{ currency }}
                    </span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field outlined dense v-model="priceSqftTo" :label="$t('toDt2') + '...'">
                  <template slot="append">
                    <span class="textDecorationBold primaryColor" style="font-size: 14px; margin-top: 4px">
                      {{ currency }}
                    </span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-1 mb-n5">
              <v-col class="" align="left">
                <span class="textDecorationBold" style="font-size: 14px">
                  {{ $t("floors") }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-select v-model="floorMin" :items="floorsList" :label="$t('from')" outlined height="30" dense
                  clearable hide-details="auto" item-value="val">
                  <template v-slot:selection="{ item }">
                    <v-row>
                      <v-col>
                        <span>
                          {{ $t(item.text) }}
                        </span>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list>
                      <v-list-item-title>
                        <v-row>
                          <v-col style="display: flex; align-items: center">
                            {{ $t(item.text) }}
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-select v-model="floorMax" :items="floorsList" :label="$t('toFilter')" outlined height="30" dense
                  clearable hide-details="auto" item-value="val">
                  <template v-slot:selection="{ item }">
                    <v-row>
                      <v-col>
                        <span>
                          {{ $t(item.text) }}
                        </span>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list>
                      <v-list-item-title>
                        <v-row>
                          <v-col style="display: flex; align-items: center">
                            {{ $t(item.text) }}
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="flex-grow-0" style="min-width:330px;">
                <MinusPlusField fieldName="bedrooms" :val="Number(bedrooms)" :label="$t('bedrooms') + ' '+ $t('toDt2')"
                  :incrementBy="incrementBy" valClass="font14 primaryColor" :disableInput="true"
                  :plusMinusChangeHandler="(i) => bedrooms = i.textFieldValue" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" align="left" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="textDecorationBold" style="font-size: 14px">
                  {{ $t("construction") }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field clearable dense outlined :class="mobileBreakpoint == true ? 'mb-n5' : ''"
                  :label="$t('yearFrom') + '...'" v-model="constructionFrom" />
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field clearable dense outlined :class="mobileBreakpoint == true ? 'mb-n5' : ''"
                  :label="$t('yearTo') + '...'" v-model="constructionTo" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" align="left" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="textDecorationBold" style="font-size: 14px">
                  {{ $t("renovation") }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field clearable dense outlined :class="mobileBreakpoint == true ? 'mb-n5' : ''"
                  :label="$t('yearFrom') + '...'" v-model="renovationFrom" />
              </v-col>
              <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-text-field clearable dense outlined :class="mobileBreakpoint == true ? 'mb-n5' : ''"
                  :label="$t('yearTo') + '...'" v-model="renovationTo" />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <v-select :items="buildingStatusList" v-model="status" return-object multiple :label="$t('status')"
                  item-text="text" item-value="val">
                  <template v-slot:selection="{ item, index }">
                    <v-row>
                      <v-col>
                        <span v-if="index == 0">
                          {{ $t(item.text) }}
                        </span>
                        <span v-if="status.length > 1 && index == 0" class="grey--text text-caption">
                          + {{ status.length - 1 }}
                          {{ $t("moreSelected") }}
                        </span>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list>
                      <v-list-item-title>
                        <v-row>
                          <v-col style="display: flex; align-items: center">
                            {{ $t(item.text) }}
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row v-if="status.length" class="pb-6 ml-0">
              <v-col align="left" class="d-flex flex-nowrap overflow-auto pt-0">
                <FilterTag v-for="(tag, index) in status" :key="index" :display="$t(tag.text)" :attrType="tag.val"
                  :deleteHandler="deleteFilter" />
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <RadioField :val="selectedMu" fieldName="selectedMu" :isRow="true" :options="MuRadioFields"
                  radioValues="MuRadioFields" :radioChangeHandler="updateRadioField" />
              </v-col>
            </v-row>


            <v-row class="">
              <v-col v-for="(index, i) in featureListFilter" :key="i" class="mt-n7 mb-n6" cols="6" xs="6" sm="4" md="4"
                lg="4" xl="4">
                <v-checkbox class="primaryColor my-checkbox" v-model="checkbox" :label="$t(index.text)"
                  :value="index.val" :size="22">
                </v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0" align="left" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="textDecorationBold" style="font-size: 14px">
                  {{ $t("callΙmportance") }}
                </span>
              </v-col>
            </v-row>


            <v-row>
              <v-col class="pb-5" align="left">
                <v-btn-toggle v-model="importance" flex>
                  <v-btn v-for="button in callΙmportance" :key="button.id" :min-width="126" :max-width="126"
                    :min-height="40" :max-height="40" class="btn recBtnWidth"
                    :class="mobileBreakpoint == true ? 'mb-2 mr-2' : 'mr-3'" outlined>
                    {{ $t(button.text) }}
                  </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pb-0 pt-2" align="left" cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <span class="textDecorationBold" style="font-size: 14px">
                  {{ $t("description") }}
                </span>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <v-textarea class="borderOutlined" v-model="description" no-resize height="124" outlined
                  :label="$t('demandDescription') + '...'">
                  {{ $t("demandDescription") }}
                </v-textarea>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-divider vertical />
      <v-col cols="12" xs="12" sm="5" md="5" lg="5" xl="5" class="py-8" align="center">
        <v-row  v-for="card in cardData" :key="card.id">
          <v-col class="pa-8">
          <PropertyCard :id="card.id" :title="card.title" :price="card.price" :location="card.location"
            :code="card.code" :status="card.status" :unit="card.unit" :typeName="card.typeName"
            :typeExtraContent="card.typeExtraContent" :propertiesArray="propertiesArray"  :similarity="card.similarity" :fromDetails="card.fromDetails"/>
          </v-col>
          </v-row>
      </v-col>
    </v-row>
  </v-col>
</template>
  
<script>
/* 
  Name:DemandCall
  Description: Component to create the demands calls
  API Property   
    callDate: String for the date that the call is done
    callTime: String for time that the call is done
    incrementBy: Number for the increase bedrooms number
    kind: String (default: "demandData") for the update data function (updateAttribute)
    updateAttribute: Function to update a attribute
    attributes: Object for the attributes

  Components Used:
    Icon
    HeaderTitle
    AvatarField
    Location
    RadioField
    MinusPlusField
    FilterTag
    PropertyCard
*/
import HeaderTitle from "@/components/HeaderTitle/HeaderTitle.vue";
import AvatarField from "@/components/Fields/AvatarField/AvatarField";
import CoreHelper from "@/helpers/Core/core";
import { Icon } from "@iconify/vue2";
import { mapGetters } from "vuex";
import Location from "@/components/Maps/Location.vue";
import i18n from "@/i18n/i18n";
import RadioField from "@/components/Fields/RadioField/RadioField.vue";
import MinusPlusField from "@/components/Fields/MinusPlusField/MinusPlusField.vue";
import FilterTag from "@/components/Filters/FilterTag.vue";
import CallLists from "@/static/calls/calls.js";
import PropertyCard from "@/components/Secretary/CreateCall/PropertyCard.vue";


export default {
  name: "DemandCall",
  components: {
    Icon,
    HeaderTitle,
    AvatarField,
    Location,
    RadioField,
    MinusPlusField,
    FilterTag,
    PropertyCard
  },
  props: {
    callDate: { type: String, default: "2/6/2022" }, //Date that the call is done
    callTime: { type: String, default: "16:09:27 μ.μ." }, //Time that the call is done
    incrementBy: { type: Number, default: 1 },
    kind: { type: String, default: "demandData" },
    updateAttribute: Function,
    attributes: Object,
  },
  async created() {
    this.currency = CoreHelper.getCurrency(false);
  },
  data() {
    return {
      callerObject: {
        name: "Γεώργιος ",
        surname: "Γεωργίου",
        mobile: "6987690025",
        phone: "2106572827",
        email: "email@gmail.com",
        afm: "126126789",
        location: "Ελλάδα, Αττική, Αθήνα, Κολωνάκι 24, 126789",
        from: "Από σύσταση",
        profileComment: "Αυτό είναι το σχόλιο για το προφίλ.",
        img: "/uploads/images/image1.png",

      },
      quickSearch: null,
      menu1: false,
      currency: null,
      MuRadioFields: [
        { label: i18n.t("leased"), value: 0 },
        { label: i18n.t("free"), value: 1 },
      ],
      featuresList: CallLists.featuresList,

      cardData: [
        {
          id: "0",
          title: "Μονοκατοικία",
          price: "415.000 €",
          location: "Κέντρο Αθήνα",
          code: "ΕΚΧ60499",
          status: "ΠΩΛΗΣΗ",
          typeName: "ΠΩΛΗΣΗ",
          unit: "138",
          typeExtraContent: "Απλή",
          similarity: 70,
          fromDetails: {
            name: "Ανδρέας ",
            surname: "Σταματόπουλος",
            img: "/general/Ellipse_1.png",
            company: "Newdeal House Brokers",
          },
        },
        {
          id: "1",
          title: "Μονοκατοικία",
          price: "415.000 €",
          location: "Κέντρο Αθήνα",
          code: "ΕΚΧ60499",
          status: "ΠΩΛΗΣΗ",
          typeName: "ΠΩΛΗΣΗ",
          unit: "138",
          typeExtraContent: "Απλή",
          similarity: 70,
          fromDetails: {
            name: "Ανδρέας ",
            surname: "Σταματόπουλος",
            img: "/general/Ellipse_1.png",
            company: "Newdeal House Brokers",
          },
        },
      ],
      propertiesArray: [
        {
          image: "realEstateSilder/bedroom.png",
          value: 6,
        },
        {
          image: "realEstateSilder/bathroom.png",
          value: 8,
        },
      ],
    }
  },
  watch: {
    nextFollowUpStart() {
      this.nextFollowUpFormatted = CoreHelper.formatDate(
        this.nextFollowUpStart
      );
    },
  },
  computed: {
    ...mapGetters([
      "availableForList",
      "realEstateCategoriesList",
      "floorsList",
      "buildingStatusList",
      "callΙmportance",
    ]),



    quickPartnerSearch: {
      get() {
        return this.attributes.quickPartnerSearch;
      },
      set(value) {
        this.updateAttribute(this.kind, "quickPartnerSearch", value);
      },
    },

    propertyCode: {
      get() {
        return this.attributes.propertyCode;
      },
      set(value) {
        this.updateAttribute(this.kind, "propertyCode", value);
      },
    },

    nextFollowUpFormatted: {
      get() {
        return this.attributes.nextFollowUpFormatted;
      },
      set(value) {
        this.updateAttribute(this.kind, "nextFollowUpFormatted", value);
      },
    },

    Category: {
      get() {
        return this.attributes.Category;
      },
      set(value) {
        this.updateAttribute(this.kind, "Category", value);
      },
    },

    nextFollowUpStart: {
      get() {
        return this.attributes.nextFollowUpStart;
      },
      set(value) {
        this.updateAttribute(this.kind, "nextFollowUpStart", value);
      },
    },

    Task: {
      get() {
        return this.attributes.Task;
      },
      set(value) {
        this.updateAttribute(this.kind, "Task", value);
      },
    },

    subCategory: {
      get() {
        return this.attributes.subCategory;
      },
      set(value) {
        this.updateAttribute(this.kind, "subCategory", value);
      },
    },

    priceFrom: {
      get() {
        return this.attributes.priceFrom;
      },
      set(value) {
        this.updateAttribute(this.kind, "priceFrom", value);
      },
    },

    priceTo: {
      get() {
        return this.attributes.priceTo;
      },
      set(value) {
        this.updateAttribute(this.kind, "priceTo", value);
      },
    },

    priceSqftFrom: {
      get() {
        return this.attributes.priceSqftFrom;
      },
      set(value) {
        this.updateAttribute(this.kind, "priceSqftFrom", value);
      },
    },

    priceSqftTo: {
      get() {
        return this.attributes.priceSqftTo;
      },
      set(value) {
        this.updateAttribute(this.kind, "priceSqftTo", value);
      },
    },

    floorMin: {
      get() {
        return this.attributes.floorMin;
      },
      set(value) {
        this.updateAttribute(this.kind, "floorMin", value);
      },
    },

    floorMax: {
      get() {
        return this.attributes.floorMax;
      },
      set(value) {
        this.updateAttribute(this.kind, "floorMax", value);
      },
    },

    constructionFrom: {
      get() {
        return this.attributes.constructionFrom;
      },
      set(value) {
        this.updateAttribute(this.kind, "constructionFrom", value);
      },
    },

    constructionTo: {
      get() {
        return this.attributes.constructionTo;
      },
      set(value) {
        this.updateAttribute(this.kind, "constructionTo", value);
      },
    },

    renovationFrom: {
      get() {
        return this.attributes.renovationFrom;
      },
      set(value) {
        this.updateAttribute(this.kind, "renovationFrom", value);
      },
    },


    renovationTo: {
      get() {
        return this.attributes.renovationTo;
      },
      set(value) {
        this.updateAttribute(this.kind, "renovationTo", value);
      },
    },

    status: {
      get() {
        return this.attributes.status;
      },
      set(value) {
        this.updateAttribute(this.kind, "status", value);
      },
    },

    bedrooms: {
      get() {
        return this.attributes.bedrooms;
      },
      set(value) {
        this.updateAttribute(this.kind, "bedrooms", value);
      },
    },


    checkbox: {
      get() {
        return this.attributes.checkbox;
      },
      set(value) {
        this.updateAttribute(this.kind, "checkbox", value);
      },
    },

    importance: {
      get() {
        return this.attributes.importance;
      },
      set(value) {
        this.updateAttribute(this.kind, "importance", value);
      },
    },

    description: {
      get() {
        return this.attributes.description;
      },
      set(value) {
        this.updateAttribute(this.kind, "description", value);
      },
    },
    selectedMu: {
      get() {
        return this.attributes.selectedMu;
      },
      set(value) {
        this.updateAttribute(this.kind, "selectedMu", value);
      },
    },

    mobileBreakpoint() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    getCategoryList() {
      return this.realEstateCategoriesList.filter(function (el, i) {
        return 0 !== i;
      });
    },
    featureListFilter() {
      var count = this.featuresList.filter((m) => {
        return m.category.includes(this.Category);
      })
      return count;
    },

  },

  methods: {
    getPhoto(obj) {
      return obj.img ? obj.img
        : obj.name && obj.surname ? obj.name.charAt(0) + obj.surname.charAt(0)
          : obj.name ? obj.name.charAt(1)
            : obj.surname ? obj.surname.charAt(1)
              : ""
    },
    updateRadioField(data) {
      this[data.fieldName] = this[data.radioValues][data.selectedRadio].value;
    },
    getSubCategoryList() {
      let subCatList = [];
      this.realEstateCategoriesList.forEach((element) => {
        if (this.Category + 1 == element.id) {
          subCatList.push.apply(subCatList, element.subCategoriesList);
        }
      });
      return subCatList;
    },
    async deleteFilter(attrHead, attrType) {
      const results = this.status.filter((obj) => {
        return obj.val != attrType;
      });
      this.status = results;
    },
    
    onClear() {
      this.quickPartnerSearch = null;
      this.propertyCode = null;
      this.nextFollowUpFormatted = null;
      this.nextFollowUpStart = null;
      this.Task = null;
      this.Category = null;
      this.subCategory = null;
      this.priceFrom = null;
      this.priceTo = null;
      this.priceSqftFrom = null;
      this.priceSqftTo = null;
      this.floorMin = null;
      this.floorMax = null;
      this.constructionFrom = null;
      this.constructionTo = null;
      this.renovationFrom = null;
      this.renovationTo = null;
      this.bedrooms = 0;
      this.status = [];
      this.checkbox = [];
      this.importance = null;
      this.description = null;
    }
  },
};
</script>

<style scoped>
.btn {
  text-transform: unset !important;
  color: #263573;
}

.v-btn-toggle--group>.v-btn.v-btn {
  border-color: #263573 !important;
}

.v-btn-toggle>.v-btn.v-btn {
  border-radius: 5px !important;
  border-width: thin !important;
  background-color: white !important;
}

.v-btn-toggle {
  border-radius: 4px;
  display: block;
  max-width: 100%;
}

.v-btn-toggle .v-btn.v-btn.v-size--default {
  min-width: 48px;
  min-height: 0;
}

.theme--light.v-btn-toggle:not(.v-btn-toggle--group) .v-btn.v-btn {
  border-color: rgba(38, 53, 115, 1) !important;
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  background-color: white;
}

#active-button {
  background-color: #263573 !important;
}

.recBtn {
  height: 108px !important;
  width: 118px !important;
}

.recBtnWidth {
  width: 118px !important;
}

.btn-custom {
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  font-size: 15px;
  background-color: white;
}
</style>