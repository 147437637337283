export default {

featuresList: [
{
  text: "leased",
  val: 1,
  category: [1, 3],
},
{
  text: "free",
  val: 2,
  category: [1, 3],
},
{
  text: "airbnb",
  val: 3,
  category: [],
},
{
  text: "warehouse",
  val: 4,
  category: [1, 3],
},
{
  text: "furnished",
  val: 5,
  category: [3],
},
{
  text: "allowingPets",
  val: 6,
  category: [],
},
{
  text: "professionalUse",
  val: 7,
  category: [2],
},
{
  text: "offPlan",
  val: 8,
  category: [2],
},
],
};
